import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import firebase from "firebase/compat/app";

import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;600&display=swap');


h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, menu, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: 'Nunito', sans-serif;

  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}
/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
    display: none;
}
body {
  line-height: 1;
}
menu, ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
* {
  box-sizing: border-box;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
}

body{
  margin: 0;
  z-index: 1;
  color: rgb(236, 234, 232);
  font-size: 1.7vw;
  font-family: 'Nunito', sans-serif;
  line-height: 2.5vw;
}

a{
  color: rgb(236, 234, 232);
}

h1{
  font-weight: 600;
}

h2{
  font-weight: 400;
  font-size: 1.4vw;
}

h3{
  padding-bottom: 4vh;
  font-size: 2.2vw;
  line-height: 3.5vw;
  font-weight: 600;
}

hr{
  border-top: 2px solid rgb(236, 234, 232);
  margin: 6vh 0px;
  display: block;
  border-width: 1.5px;
}

input{
  color: rgb(236, 234, 232);
  font-family: 'Nunito', sans-serif;
  font-size: 1.2vw;
}

input[type=text]{
  border: none;
  border-bottom: 3px solid rgb(236, 234, 232);
  padding: 1vh 1vw;
  margin-bottom: 3vh;
  outline: none;
  line-height:140%;
  resize: none;
  background: rgba(255,255,255, 0.2);
  ::placeholder{
    color: rgb(236, 234, 232);
    font-size: 1.2vw;
  }
}

input[type=submit], button{
  color: rgb(236, 234, 232);
  font-size: 1.2vw;
  border: 3px solid rgb(236, 234, 232);
  border-radius: 15px;
  background: rgba(255,255,255, 0.2);
  padding: 0.5vh 0.8vw;
  margin-right: 0.75vw; 
  cursor: pointer;
  transition: 0.2s;
  font-family: 'Nunito', sans-serif;
}

input[type=submit]:hover, button:hover{
  background: rgb(236, 234, 232);
  color: rgb(164, 161, 144);

}

select{
  font-size: 1.2vw;
  border: 0 solid #9b67cf;
  border-bottom: 3px solid rgb(236, 234, 232);
  background: rgba(255,255,255, 0.2);
  outline: none;
  color: rgb(236, 234, 232);
  font-family: 'Nunito', sans-serif;
  padding: 1vh 1vw;
  margin-bottom: 3vh;
  width: 15vw;
}

@media only screen and (max-width: 768px){
  body, h2, input{
    font-size: 20px;
    line-height: 30px;
  }

  h1{
    font-size: 24px;
  }

  h3{
    font-size: 27px;
    line-height: 42px;
  }

  input[type=submit], button{
    font-size: 16px;
    border-radius: 20px;
    padding: 1vh 1.5vw;
    margin-right: 0.75vw; 
  }

  input[type=text]{
    line-height:140%;
    resize: none;
    ::placeholder{
      font-size: 16px;
    }
  }

  select{
    font-size: 16px;
    width: 91vw;
  }
`;


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GlobalStyle />
    <App />
  </React.StrictMode>
);
