import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { useScroll } from "framer-motion";
import { useLocation, useHistory } from 'react-router-dom';
import { doc, getDoc } from "firebase/firestore";
import {dbService} from "../myBase";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Mobile = 699;

const DBox = styled.div`
    color: rgb(164, 161, 144);
    background-color: rgb(236, 234, 232);
    width: 96vw;
    // height: 150vh; 
    height: '10vh';
    padding: 8vh 6vw;
    border-radius: 30px;
    text-align: center;
    margin-bottom: 10vh;
    h1{
        margin-bottom: 6vh;
        font-weight: 600;
        font-size: 3vw;
        line-height: 4.5vw;
    }
    p{
        margin-bottom: 2vh;
    }
    a{
        color: rgb(164, 161, 144);
    }
    @media only screen and (max-width: ${Mobile}px){
        padding: 6vh 6vw;
        h1{
            font-size: 30px;
            line-height: 40px;
            margin-bottom:3vh;
        }
    }
`

const Door = styled.img`
    // border-radius: 30px;
    width: 80vw;
    max-height: 70vh;
    width: auto;
    margin: 5vh 0;
    @media only screen and (max-width: ${Mobile}px){
        width: auto;
        max-height: 50vh;
    }
`

const Middle = styled.div`
    text-align: left;
    div{ 
        float: left;
        width: 60%;
        &:first-child{
            width: 40%;
        }
    }
    @media only screen and (max-width: ${Mobile}px){
        div{
            float: none;
            width: 100%;
            margin: 3vh 0;
            &:first-child{
                width: 100%;
            }
        }
    }
`

const ADetail = () => {
    const location = useLocation();
    const id = window.location.href.slice(39, 60)
    console.log(id)

    const history = useHistory();

    const [ews, setEws] = useState([]);
    
    const {scrollY} = useScroll();

    const getData = async () => {
        const q = doc(dbService, "archive_sel", id);
        const docSnap = await getDoc(q)
        
        
        if (docSnap.exists()) {
            setEws(docSnap.data())
          } else {
            console.log("No such document!");
          }
        };

    useEffect(()=>{
        getData();
    },[])

    return(
        <DBox style={{
            top: scrollY.get() +50
          }}>
              <span 
                style={{cursor:"pointer"}}
                onClick={history.goBack}>
                <FontAwesomeIcon icon={faXmark} size="1x"/>
              </span>
              <br/>
              <Door src={ews.image}/>
              <h1>{ews.title}</h1>
                <Middle>
                    <div>
                        · author: {ews.author} (
                            <a href={ews.email} target="_blank">email</a> / <a target="_blank" href={ews.insta}>instagram</a>
                        )<br/>
                        · price: {ews.price}<br/>                        
                    </div>
                    <div>
                        {ews.story}
                    </div>

                    <p style={{marginBottom: 0}}>&nbsp;</p>
                </Middle>
                
        </DBox>
    )
}

export default ADetail;