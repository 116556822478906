import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { useScroll } from "framer-motion";
import { useHistory } from 'react-router-dom';
import { doc, getDoc } from "firebase/firestore";
import {dbService} from "../myBase";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Mobile = 699;

const DBox = styled.div`
    color: rgb(164, 161, 144);
    background-color: rgb(236, 234, 232);
    width: 96vw;
    // height: 100vh; 
    height: '80vh';
    padding: 8vh 6vw;
    margin-bottom: 10vh;
    border-radius: 30px;
    text-align: center;
    h1{
        margin-bottom: 6vh;
        font-weight: 600;
        font-size: 3vw;
        line-height: 4.5vw;
    }
    p{
        margin-bottom: 2vh;
    }
    a{
        color: rgb(164, 161, 144);
    }

    @media only screen and (max-width: ${Mobile}px){
        padding: 6vh 6vw;
        h1{
            font-size: 30px;
            line-height: 40px;
            margin-bottom:3vh;
        }
    }
`

const Door = styled.img`
    border-radius: 30px;
    width: 80vw;
    max-height: 70vh;
    width: auto;
    margin: 5vh 0;
    @media only screen and (max-width: ${Mobile}px){
        width: 80vw;
        margin: 3vh 0;
    }
`

const Image = styled.img`
    border-radius: 30px;
    width: 26.5vw;
    margin-top: 2vh;
    margin-left: 1.5vw;
    vertical-align: top;
    @media only screen and (max-width: ${Mobile}px){
        width: 48%;
    }
`
const Middle = styled.div`
    text-align: left;
    div{
        float: left;
        width: 60%;
        &:first-child{
            width: 40%;
        }
    }
    @media only screen and (max-width: ${Mobile}px){
        div{
            float: none;
            width: 100%;
            margin: 3vh 0;
            &:first-child{
                width: 100%;
            }
        }
    }
`

const EDetail = () => {
    const id = window.location.href.slice(37, 58)
    console.log(id)

    const history = useHistory();

    const [ews, setEws] = useState([]);
    const [door, setDoor] = useState("")
    
    const {scrollY} = useScroll();

    const getData = async () => {
        const q = doc(dbService, "event", id);
        const docSnap = await getDoc(q)
        
        
        if (docSnap.exists()) {
            setEws(docSnap.data())
            setDoor(docSnap.data().image[0])
          } else {
            console.log("No such document!");
          }
        };

    useEffect(()=>{
        getData();
    },[])

    return(
        <DBox style={{
            top: scrollY.get() +50
          }}>
              <span 
                style={{cursor:"pointer"}}
                onClick={history.goBack}>
                <FontAwesomeIcon icon={faXmark} size="1x"/>
              </span>
              <br/>
              <Door src={door}/>
              <h1>{ews.title}</h1>
                <Middle>
                    <div>
                        · when: {ews.when}<br/>
                        · where: {ews.where}<br/>
                        {ews.link!=""&&<span>· <a href={ews.link} target="_blank">link</a></span>}
                        
                    </div>
                    <div>
                        {ews.story?.map((str)=> (
                            <p>{str}</p>
                        ))}
                    </div>
                
                    {ews.image?.map((ima, index)=> (
                        <a href={ima} target="_blank"><Image key={index} src={ima}/></a>
                    ))}
                </Middle>
                
        </DBox>
    )
}

export default EDetail;