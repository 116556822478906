import React, { useState, useEffect } from "react";
import { addDoc, collection, query, getDocs } from "firebase/firestore";
import {dbService} from "../myBase";

const Home2 = () => {
    const [ew, setEw] = useState("");
    const [name, setName] = useState("");
    const [ews, setEws] = useState([]);

    const onSubmit = async(event) => {
        event.preventDefault();
        try {
            const docRef = await addDoc(collection(dbService, "event"), {
            text: ew,
            writer: name,
            createdAt: Date.now(),
            });
            console.log("Document written with ID: ", docRef.id);
        } catch (error) {
            console.error("Error adding document: ", error);
        }
        setEw("");
        }

    const onChange = (event) => {
        const {target:{value}} = event
        setEw(value);
    }

    const getEws = async () => {
        const q = query(collection(dbService, "event"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
        const nweetObj = {
            ...doc.data(),
            id: doc.id,
        }
        setEws(prev => [nweetObj, ...prev]);
        });
        };

    useEffect(()=> {
        getEws();
        console.log(ews)
    },[])

    return(
        <>
            <form onSubmit={onSubmit}>
                <input value={name} onChange={onChange} type="text" placeholder="your name?"/>
                <input value={ew} onChange={onChange} type="text" placeholder="how was our event?"/>
                <input type="submit" value="Ew"/>
            </form>

            <div>
                {ews.map((ew) => (
                    <div key={ew.createdAt}>
                        <h4>{ew.ew}</h4>
                    </div>
                ))}
            </div>
        </>)
}

export default Home2;