import React, { useState } from "react";
import {HashRouter as Router, Route, Switch} from "react-router-dom";
import Home from "../routes/Home";
import About from "../routes/About";
import Event from "../routes/Event";
import Archive from "../routes/Archive";
import ArchivePer from "../routes/ArchivePer";
import Guest from "../routes/Guest";
import Home2 from "../routes/Home2";
import { Helmet } from "react-helmet";

const AppRouter = () => {
    
    return(
        <Router>
            <Helmet>
                <title>Not Just a Collective</title>
            </Helmet>
            <Switch>
                    <Route exact path="/">
                        <Home/>
                    </Route>
                    <Route exact path="/about">
                        <About/>
                    </Route>
                    <Route exact path={["/event", "/event/:eventId"]}>
                        <Event/>
                    </Route>
                    <Route exact path={["/archive", "/archive/:eventId"]}>
                        <Archive/>
                    </Route>
                    <Route exact path={["/archiveper", "/archiveper/:eventId"]}>
                        <ArchivePer/>
                    </Route>
                    <Route exact path={["/guest","/guest/:tag"]}>
                        <Guest/>
                    </Route>
                    <Route exact path="/home2">
                        <Home2/>
                    </Route>
            </Switch>
        </Router>
    )
 }
 
 
 export default AppRouter;