import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useLocation, Link } from "react-router-dom";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Mobile = 699;

const Back = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url(https://raw.githubusercontent.com/eunaleeeunalee/NJC/master/bg.gif);
  background-size: cover;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 1;
`

const Container = styled.div`
    position: fixed;
    width: 100%;
    height: 12vh;
    z-index: 10;
    display: flex;
    margin: 0;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background-image: url(https://raw.githubusercontent.com/eunaleeeunalee/NJC/master/bg.gif);
    background-size: cover;
    font-weight: 600;


    @media only screen and (max-width: ${Mobile}px){
        background-image: none;
        position: relative;
        font-size: 27px;
    }
`

const Box = styled.div`
    height: 12vh;
    width: 96%;
    border-bottom: 3px solid rgb(236, 234, 232);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    z-index: 35;
`

const Left = styled.div`
    float: left;
    width: 50%;
    text-align: left;
    margin-left: 1vw;
    // z-index: 25;
`

const Right = styled.div`
    float: left;
    width: 50%;
    text-align: right;
    margin-right: 1vw;
    // z-index: 25;
`

const Piece = styled.span`
    margin-right: 1.5vw;
    a{
        text-decoration: none;
    }
    &:last-child{
        margin-right: 0vw;
    }
    &:hover{
        text-decoration: underline;
    }
`

const Bigopen = styled.div`
    z-index: 20;
    background-image: url(https://raw.githubusercontent.com/eunaleeeunalee/NJC/master/bg.gif);
    background-size: cover;
    position: fixed;
    width: 100vw;
    height: 100vh;
    left:0;
    top:0;
`

const Inneropen = styled.div`
    font-size: 36px;
    line-height: 60px;
    font-weight: 400;
    margin-top: 18vh;
    margin-left: 8vw;
    a{
        text-decoration: none;
    }
`


const Top = () => {
    const [mob, setMobile] = useState(false);
    const [open, setOpen] = useState(false);
    const [bars, setBars] = useState(true);

    const location = useLocation();

    const [adress, setAdress] = useState(location.pathname);
    console.log(adress);

    const onOpen = () => {
        setBars(!bars)
        setOpen(!open);
    }

    useEffect(()=>{
        const {innerWidth: width, innerHeight: height} = window;
        // console.log(width, height);
        if(width<768){
            setMobile(true);
        }
    },[])
    return(
        <>
            <Back/>
            <Container>
                <Box>
                    <Left>
                        <Piece>
                        <Link to="/">
                            {mob? <span>NJAC</span>:
                            <span>Not just a Collective</span>}
                        </Link>
                        </Piece>
                    </Left>
                    
                    {mob? 
                    <Right>
                        {bars?
                            <FontAwesomeIcon 
                            style={{cursor: "pointer"}}
                            icon={faBars} 
                            onClick = {() => onOpen()} />
                            :<FontAwesomeIcon 
                            style={{cursor: "pointer"}}
                            icon={faXmark} 
                            onClick = {() => onOpen()} />
                        }
                    </Right>
                    :<Right>
                        {adress == "/about" ?  
                            <Piece style={{textDecoration:"underline"}}><Link to="/about">about</Link></Piece>
                            :<Piece><Link to="/about">about</Link></Piece>}

                        {adress == "/event" ?  
                            <Piece style={{textDecoration:"underline"}}><Link to="/event">event</Link></Piece>
                            :<Piece><Link to="/event">event</Link></Piece>}

                        {adress == "/archive" ?  
                            <Piece style={{textDecoration:"underline"}}><Link to="/archive">archive</Link></Piece>
                            :<Piece><Link to="/archive">archive</Link></Piece>}

                        {adress == "/guest" ?  
                        <Piece style={{textDecoration:"underline", paddingRight: 0}}><Link to="/guest">guest_book</Link></Piece>
                        :<Piece style={{paddingRight: 0}}><Link to="/guest">guest_book</Link></Piece>}
 
                    </Right>}

                    
                </Box>
                    {open&&
                        <Bigopen>
                            <Inneropen>
                                {adress == "/about" ?  
                                <Piece style={{textDecoration:"underline"}}><Link to="/about">about</Link></Piece>
                                :<Piece><Link to="/about">about</Link></Piece>}
                                <br/>

                                {adress == "/event" ?  
                                    <Piece style={{textDecoration:"underline"}}><Link to="/event">event</Link></Piece>
                                    :<Piece><Link to="/event">event</Link></Piece>}
                                <br/>

                                {adress == "/archive" ?  
                                    <Piece style={{textDecoration:"underline"}}><Link to="/archive">archive</Link></Piece>
                                    :<Piece><Link to="/archive">archive</Link></Piece>}
                                <br/>

                                {adress == "/guest" ?  
                                    <Piece style={{textDecoration:"underline", paddingRight: 0}}><Link to="/guest">guest book</Link></Piece>
                                    :<Piece style={{paddingRight: 0}}><Link to="/guest">guest book</Link></Piece>}
                                <br/>
                                
                            </Inneropen>
                        </Bigopen>
                    }
            </Container>
        </>
    )
}

export default Top;