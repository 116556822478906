import React, { useRef, useEffect, useCallback } from "react";
import styled from 'styled-components';
import Top from "../components/Top";

const Mobile = 699;

const Content = styled.div`
    left: 30%;
    position: relative;
    height: 100vh;
    width: 70%;
    padding: 16vh 2vw;
    padding-bottom: 50vh;
    z-index: 5;
    @media only screen and (max-width: ${Mobile}px){
        left: 0;
        width: 96vw;
        margin-left: 1vw;
        padding-top: 5vh;
    }
`

const Innertext = styled.div`
    margin: 0px 1vw 0px 1.5vw;
    &:last-child{
        margin-bottom: 5vw;
    }
`

const Profile = styled.img`
    @media only screen and (max-width: ${Mobile}px){
        width: 90vw;
    }
`

const Box = styled.div`
    height: 10vh;
    width: 96vw;
    border-top: 3px solid rgb(236, 234, 232);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    z-index: 10;
    position: relative;
    left: -30vw;
    font-size: 1.2vw;
    @media only screen and (max-width: ${Mobile}px){
        font-size: 16px;
        left: -1vw;
    } 
`


const About = () => {
    const mapRef = useRef(null);

    const initMap = useCallback(() => {
      const post = { lat: 51.984004763612866, lng: 5.920544607698171 }
      const map = new window.google.maps.Map(mapRef.current, {
        center: post,
        zoom: 17,
        options:{
            disableDefaultUI: true,
            styles: [{featureType: "poi", elementType: "labels", stylers:[{visibility: "off"}]}]
        },
      });
      new window.google.maps.Marker({
        position: post,
        map:map,
        label:{
            text: "P-OST Galllery"},
        icon: {
            url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
            labelOrigin: new window.google.maps.Point(20, 45)
        }
      })
}, [mapRef]);

    useEffect(() => {
        initMap();
      }, [initMap]);
    return(
        <>
            <Top/>
            <Content>
                    
                    <h3>About</h3>
                    <Innertext>
                    Not Just a Collective consists of a group of multidisciplinary artists, researchers and designers who share a common desire to discuss artistic progress and create a space for emerging international artists. To work on a supporting framework together, Not Just a Collective set out to host a series of art and cultural activities contributing to multi-disciplinary practices in art ecology. Moreover, it is not only a collective, in conventional perception, but also a community aiming to breed new initiatives and foster cultural participation by facilitating accessibility.
                    <br/><br/>
                    We are based at @platform_post a platform for contemporary art based in Arnhem (NL). Come check Not Just a Library.
                    </Innertext>

                <hr/>

                    <h3>Members</h3>
                    <Innertext>
                    <Profile src="https://github.com/eunaleeeunalee/NJC/blob/master/profile.png?raw=true"/>
                    · Alessandra Varisco<br/>
                    · Euna Lee<br/>
                    · Femke Kersten<br/>
                    · Heike Renée<br/>
                    · Han gyeol Kim<br/>
                    · Jiahui Feng<br/>
                    · Juliana Garcia Bello<br/>
                    · Lu Lin<br/>
                    · Santiago Candelo<br/>
                    · Sem Larisa Jannik<br/>
                </Innertext>

                <hr/>

                    <h3>Contact</h3>
                    <Innertext style={{paddingBottom: "7vh"}}>
                        · Email: <a href="mailto:info.notjustacollective@gmail.com">info.notjustacollective@gmail.com</a>
                        <br/>
                        · Instagram: @<a href="https://www.instagram.com/not.just.a.collective/">not.just.a.collective</a>
                        <br/>
                        · Adress: POST Arnhem Driekoningenstraat 16 6828 EN Arnhem, NL
                        <div
                        style={{ width: "45vh", height: "45vh"}}
                        ref={mapRef}/>

                    </Innertext>
                    <Box>This website is made by 
                        <a 
                            style={{marginLeft:"0.5vw"}}
                            href="https://euna-lee.com/" target="_blank">eunalee</a>
                    </Box>
            </Content>
        </>
    )
}

export default About;