import React, { useState, useEffect } from "react";
import Top from "../components/Top";
import EDetail from "./EDetail";
import styled from 'styled-components';
import { collection, query, getDocs, orderBy } from "firebase/firestore";
import {dbService} from "../myBase";
import { useHistory, useRouteMatch} from "react-router-dom"

const Mobile = 699;

const Content = styled.div`
    position: relative;
    height: 100vh;
    padding: 16vh 2vw;
    padding-bottom: 50vh;
    
    z-index: 5;
    color: rgb(164, 161, 144);
    h4{
        font-weight: 400;
    }

    @media only screen and (max-width: ${Mobile}px){
        padding-top: 3.5vh;
    }
`

const Small = styled.div`
    margin-bottom: 3.5vh;
    margin-right: 0.5vw;
    margin-left: 0.5vw;
    cursor: pointer;
    background-color: rgb(236, 234, 232);
    width: 23vw;
    height: 50vh;
    float: left;
    background-size: cover;
    background-position: center center;
    border-radius: 30px;
    background-image: url(${props => props.bg});
    position: relative;
    top:0;
    transition: all 0.2s;
    
    div{
        opacity: 0;
        
    }
    &:hover{
       
        top: 1vh;
        div{
            opacity: 1;
            
        }
    }
    @media only screen and (max-width: ${Mobile}px){
        margin-bottom: 2.5vh;
        width: 94vw;
        height: 30vh;
    }
`

const Small2 = styled.div`
    margin-bottom: 3.5vh;
    margin-right: 0.5vw;
    margin-left: 0.5vw;
    padding: 1.5vh 1.5vw;
    background-color: rgb(236, 234, 232);
    width: 23vw;
    height: 50vh;
    float: left;
    background-size: cover;
    background-position: center center;
    border-radius: 30px;
    position: relative;
    top:0;
    transition: all 0.2s;
    font-weight: 600;
    @media only screen and (max-width: ${Mobile}px){
        width: 94vw;
        height: 9vh;
        margin-bottom: 2.5vh;
        -webkit-box-align: center;
        align-items: center;
        display: flex;
        padding: 1.5vh 3.5vw;
    }
`


const Info = styled.div`
    background-color: rgb(236, 234, 232, 0.9);
    height: 100%;
    border-radius: 30px;
    padding: 1.5vh 1.5vw;
    @media only screen and (max-width: ${Mobile}px){
        margin-bottom: 2.5vh;
        width: 94vw;
        height: 30vh;
        padding: 1.5vh 3vw;
    }
`

const DeBox = styled.div`
    position: absolute;
    left: 2vw;
    top: 14vh;
    z-index: 20;
`

const Box = styled.div`
    height: 10vh;
    width: 96vw;
    border-top: 3px solid rgb(236, 234, 232);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    z-index: 10;
    color: rgb(236, 234, 232);
    font-size: 1.2vw;
    @media only screen and (max-width: ${Mobile}px){
        font-size: 16px;
    }
`

const Event = () => {
    const [ews, setEws] = useState([]);
    const EventMatch = useRouteMatch("/event/:eventId");

    const history = useHistory();

    const onBoxClicked = (eventId) => {
        history.push(`/event/${eventId}`);
        console.log(eventId)
        scrollToTop();
    }

    const getEws = async () => {
        const q = query(collection(dbService, "event"), orderBy("code"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
        const nweetObj = {
            ...doc.data(),
            id: doc.id,
        }
        setEws(prev => [nweetObj, ...prev]);
        });
        };
    
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };    

    useEffect(()=> {
        getEws();
        console.log(ews)
    },[])
    return(
        <>
            <Top/>
            <Content>
                
                    {ews.map((ew) => (
                        <>
                        {ew.cat == "cut"?
                        <Small2
                            bg={ew.image[0]}
                            key={ew.title}>
                            {ew.title}
                        </Small2>
                        :<>
                        <Small
                            onClick = {() => onBoxClicked(ew.id)} 
                            bg={ew.image[0]}
                            key={ew.title}>
                            <Info>
                                {ew.title}
                                <br/>
                                <h2>{ew.when}</h2>
                            </Info>
                        </Small>
                        <DeBox>
                            {EventMatch ? 
                                    <EDetail id={ew.id}/>
                            :null}
                        </DeBox>
                        </>
                        }
                        </>
                    ))}
            <div 
                style={{float: "left", width: "90vw", height: "2vh", marginBottom: 0}}>
                    &nbsp;
            </div>
            <Box>This website is made by 
                <a 
                    style={{marginLeft:"0.5vw"}}
                    href="https://euna-lee.com/" target="_blank">eunalee</a>
            </Box>
            
            </Content>
            
           
        </>
    )
}

export default Event;