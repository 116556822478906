import React, {useState, useEffect} from 'react';
import styled, {keyframes} from 'styled-components';
import { Link } from 'react-router-dom'

const Mobile = 699;

const hello = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;


const First = styled.div`
  display: flex;
  justify-content: center; 
  align-items: center;
  width: 100%;
  height: 100vh;
  background-image: url(https://raw.githubusercontent.com/eunaleeeunalee/NJC/master/bg.gif);
  background-size: cover;
  position: fixed;
  left: 0px;
  top: 0px;
  a{
    padding-right: 1.5vw;
    text-decoration: none;
    font-size: 1.7vw;
    &:hover{
      text-decoration: underline;
    }
  }
  @media only screen and (max-width: ${Mobile}px){
    a{
      font-size: 22px;
      line-height: 36px;
      padding-right: 3vw;
      &:last-child{
        padding-right: 0;
      }
    }
  }
`

const Inner = styled.div`
  text-align: center;
  h2{

    font-weight: 600;
    opacity: ${props => props.open? 0: 1};
  }
  @media only screen and (max-width: ${Mobile}px){
    width: 96vw;
    margin-top: -5vh;
  }
`

const Up = styled.h1`
  animation-fill-mode: forwards;
  opacity: 0;
  animation-duration: 3s;
  animation-name: ${hello};
  animation-delay: 1.5s;
`


const Logo = styled.img`
  padding: 12vh 0px;
  cursor: pointer;
  width: 35vw;
  filter: drop-shadow(rgb(250, 169, 213) 15px 7px 8px);
  animation-fill-mode: forwards;
  opacity: 0;
  animation-duration: 3s;
  animation-name: ${hello};
  animation-delay: 1s;
  transition: all 0.2s;
  &:hover{
    margin-top: 1vh;
    margin-left: 1vw;
  }
  @media only screen and (max-width: ${Mobile}px){
    height: 65vh;
    width: auto;
  }
`

const Auth = () => {
    const [mob, setMobile] = useState(false);
    const [open, setOpen] = useState(true);
    const audio = new Audio("https://raw.githubusercontent.com/eunaleeeunalee/NJC/master/popop.wav")

    const wtf = () => {
      audio.play();
      setOpen(!open);
      console.log(open);
    }

    useEffect(()=>{
      const {innerWidth: width, innerHeight: height} = window;
      // console.log(width, height);
      if(width<768){
          setMobile(true);
      }
  },[])

    return(
        <First>
            <Inner>
            <Up>Not just a Collective</Up>
            <Logo 
                // onClick={()=> setOpen(prev=>!prev)}
                onClick={wtf}
                src="https://github.com/eunaleeeunalee/NJC/blob/master/title2.png?raw=true"/>
            <h2
                style={{
                "opacity": open? "0":"1" 
                }}
                >
                  · <Link to="/about">about</Link>
                  · <Link to="/event">event</Link>
                  · <Link to="/archive">archive</Link>
                  {mob&& <br/>}
                  · <Link to="/guest">guest_book</Link>    
              </h2>
            </Inner>
        </First>
    )
    
}

export default Auth;