import React, {useState, useEffect} from 'react';
import AppRouter from "./Router";

function App() {


  return (
    <AppRouter/>
  );
}

export default App;
