import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGIN_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUR_ID
  };

// const firebaseConfig = {
//   apiKey: "AIzaSyBDHca_bDtlQ0b97Y-CFKu4VI_mCx2Zqs8",
//   authDomain: "njac-3e27d.firebaseapp.com",
//   projectId: "njac-3e27d",
//   storageBucket: "njac-3e27d.appspot.com",
//   messagingSenderId: "327706303050",
//   appId: "1:327706303050:web:61e7a0db5bc011dbb624b3",
//   measurementId: "G-KE6NJP2ZD5"
// };
  
  initializeApp(firebaseConfig);

  export const authService = getAuth();
  export const dbService = getFirestore();
  // export const storageService = getStorage();