import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from 'styled-components';
import Top from "../components/Top";
import { useHistory } from 'react-router';
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { addDoc, collection, query, getDocs, orderBy } from "firebase/firestore";
import {dbService} from "../myBase";

const Mobile = 699;

const Tag = styled.div`
    left: 0;
    height: 100vh;
    width: 30vw;
    padding: 16vh 2vw;
    padding-bottom: 50vh;
    z-index: 5;
    margin-left: 1vw;
    position: fixed;
    button{
        margin-bottom: 2vh;
    }
    @media only screen and (max-width: ${Mobile}px){
        display: none;
        // width: 96vw;
        // height: auto;
        // margin-left: 1vw;
        // position: relative;
        // height: auto;
        // padding-bottom: 2vh;
    }
`

const Content = styled.div`
    position: relative;
    left: 30vw;
    height: 100vh;
    width: 70vw;
    padding: 16vh 2vw;
    padding-bottom: 50vh;
    z-index: 5;
    @media only screen and (max-width: ${Mobile}px){
        left: 0;
        width: 96vw;
        margin-left: 1vw;
        padding-top: 5vh;
        // padding-top: 2vh;
    }
`

const Innertext = styled.div`
    margin: 0px 1vw 0px 1.5vw;
    &:last-child{
        margin-bottom: 5vw;
    }
`

const Inputtext = styled.input`
    width: 15vw;
    @media only screen and (max-width: ${Mobile}px){
        width: 91vw;
    }
`

const Inputtext2 = styled.input`
    width: 45vw;
    @media only screen and (max-width: ${Mobile}px){
        width: 91vw;
    }
`

const When = styled.span`
    font-size: 1.2vw;
    margin-left: 1vw;
    @media only screen and (max-width: ${Mobile}px){
        font-size: 12px;
    }
`

const Subject = styled.em`
    margin-left: 1vw;
`

const Box = styled.div`
    height: 10vh;
    width: 96vw;
    border-top: 3px solid rgb(236, 234, 232);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    z-index: 10;
    position: relative;
    left: -30vw;
    font-size: 1.2vw;
    @media only screen and (max-width: ${Mobile}px){
        font-size: 16px;
        left: -1vw;
    } 
`

const H3 = styled.h3`
    @media only screen and (max-width: ${Mobile}px){
        padding-bottom: ${props => props.open? '4vh': '0vh'};
    }
`

const Guest = () => {
    const [text, setEw] = useState("");
    const [name, setName] = useState("");
    const [subject, setSubject] = useState("njaf");
    const [ews, setEws] = useState([]);
    const [what, setWhat]= useState("");
    const [mob, setMobile] = useState(false);
    const [open, setOpen] = useState(false);

    const history = useHistory();

    const onSubmit = async(event) => {
        event.preventDefault();
        try {
            const docRef = await addDoc(collection(dbService, "guest"), {
            text: text,
            name: name,
            subject: subject,
            createdAt: Date.now(),
            });
            console.log("Document written with ID: ", docRef.id);
        } catch (error) {
            console.error("Error adding document: ", error);
        }
        setEw("");
        history.go(0)
        }

    const onChange = (event) => {
        const {target:{value}} = event
        setEw(value);
    }

    const onChangeName = (event) => {
        const {target: {value}} = event;
        setName(value); 
    }
    const onChangeSubject = (event) => {
        const {value} = event.target;
        // const {target: {value}} = event;
        setSubject(value); 
        console.log(value);
    }

    const getEws = async () => {
        const q = query(collection(dbService, "guest"), orderBy("createdAt"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
        const nweetObj = {
            ...doc.data(),
            id: doc.id,
        }
        setEws(prev => [nweetObj, ...prev]);
        });
        };

    useEffect(()=> {
        getEws();
        const {innerWidth: width, innerHeight: height} = window;
        if(width<768){
            setMobile(true);
        };
        console.log(ews);
    },[])

    return(
        <>
            <Top/>
            <Tag>
                <button onClick={()=> setWhat("")}>All</button>
                <button onClick={()=> setWhat("njaf")}>#NJAF 2023</button>
                <button onClick={()=> setWhat("survival")}>#Survival Manual for Self-publisher</button>
                <button onClick={()=> setWhat("library")}>#library</button>
                <button onClick={()=> setWhat("etc")}>#etc</button>
            </Tag>
            <Content>
                <H3
                    style={{"paddingBottom": open? "4vh":"0" }}
                    >Share your thoughts 
                    {mob&&
                        <span>
                            {open?
                                <FontAwesomeIcon 
                                style={{cursor: "pointer", marginLeft:"2vw"}}
                                icon={faCaretUp} 
                                onClick={()=> setOpen(!open)} />
                                :<FontAwesomeIcon 
                                style={{cursor: "pointer", marginLeft:"2vw"}}
                                icon={faCaretDown} 
                                onClick={()=> setOpen(!open)} />
                            }
                        </span>}
                </H3>
                
                {mob?
                <Innertext>
                    {open && 
                        <form onSubmit={onSubmit}>
                            <div>
                            <select onChange={onChangeSubject}>
                                <option value="njaf">njaf 2023</option>
                                <option value="survival">Survival Manual for Self-publisher</option>
                                <option value="library">library</option>
                                <option value="etc">etc</option>
                            </select>
                            </div>
                            <Inputtext 
                                required
                                style={{marginRight:"1vw"}}
                                value={name} 
                                onChange={onChangeName} 
                                type="text" 
                                placeholder="what is your name?"/>
                            <Inputtext2
                                required
                                value={text} 
                                onChange={onChange} 
                                type="text" 
                                placeholder="What do you want to say?"/>
                            
                            <input type="submit" value="Submit"/>
                        </form>
                    }
                </Innertext>
                :<Innertext>
                    <form onSubmit={onSubmit}  style={{marginTop: '4vh'}}>
                        <div>
                        <select onChange={onChangeSubject}>
                            <option value="njaf">njaf 2023</option>
                            <option value="survival">Survival Manual for Self-publisher</option>
                            <option value="library">library</option>
                            <option value="etc">etc</option>
                        </select>
                        </div>
                        <Inputtext 
                            required
                            style={{marginRight:"1vw"}}
                            value={name} 
                            onChange={onChangeName} 
                            type="text" 
                            placeholder="what is your name?"/>
                        <Inputtext2
                            required
                            value={text} 
                            onChange={onChange} 
                            type="text" 
                            placeholder="What do you want to say?"/>
                        
                        <input type="submit" value="Submit"/>
                    </form>
                </Innertext>}
            
            <hr/>

            <h3>Guest Book</h3>
            {what == ""?
                <Innertext style={{marginBottom: "5vh"}}>
                        {ews.map((ew) => (
                            <div key={ew.createdAt} style={{marginBottom: "2vh"}}>
                                · {ew.name}
                                <When>
                                    ({`${new Date(ew.createdAt).toLocaleDateString()} ${new Date(ew.createdAt).toLocaleTimeString()}`})
                                </When>
                                <Subject>#{ew.subject}</Subject>
                                <br/>
                                <span style={{marginLeft: "0.7vw", marginBottom:"3vh"}}>
                                    {ew.text}
                                </span>
                            </div>
                        ))}
                    <br/>
                </Innertext>
            :null}

            {what == "njaf" || what == "survival" || what == "library" || what == "etc" ?
                <Innertext style={{marginBottom: "5vh"}}>
                {ews.map((ew) => (
                    <>
                    {ew.subject == what ?
                        <div key={ew.createdAt} id={ew.subject}>
                            · {ew.name}
                            <When>
                                ({`${new Date(ew.createdAt).toLocaleDateString()} ${new Date(ew.createdAt).toLocaleTimeString()}`})
                            </When>
                            <Subject>#{ew.subject}</Subject>
                            <br/>
                            {ew.text}
                        </div>:null}
                    </>
                ))}
                <br/>
                </Innertext>
            :null}
                <Box>This website is made by 
                        <a 
                            style={{marginLeft:"0.5vw"}}
                            href="https://euna-lee.com/" target="_blank">eunalee</a>
                </Box>
            </Content>
        </>
    )
}

export default Guest;